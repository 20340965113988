import { getDidacticTypes } from 'app/crud/didacticTypes.crud';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PERSIST_DIDACTIC_TYPES, REDUCER_DIDACTIC_TYPES } from '../conf';

export const actionTypes = {
  GetDidacticTypes: 'cms/GET_DIDACTIC_TYPES',
  SetDidacticTypes: 'cms/SET_DIDACTIC_TYPES',
};

const initialState = {
  data: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_DIDACTIC_TYPES, whitelist: ['data'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetDidacticTypes:
      return { ...state, loading: true };

    case actionTypes.SetDidacticTypes: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        return { data: response.data, loading: false };
      }
      return { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getDidacticTypes: (state) => {
    return state.entities[REDUCER_DIDACTIC_TYPES].data;
  },
};

export const actions = {
  getDidacticTypes: (params) => ({ type: actionTypes.GetDidacticTypes, payload: { params } }),
  fulfillDidacticTypes: (response) => ({ type: actionTypes.SetDidacticTypes, payload: { response } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetDidacticTypes, function* getDidacticTypesSaga(action) {
    // Intentar obtener los datos de la caché primero
    const cachedDidacticTypes = yield select(selectors.getDidacticTypes);

    // Si hay datos en la caché, los usamos directamente
    if (cachedDidacticTypes && cachedDidacticTypes.length > 0) {
      yield put(actions.fulfillDidacticTypes(cachedDidacticTypes));
    } else {
      // Si no hay datos en la caché, llamamos a la API para obtenerlos
      const { data } = yield getDidacticTypes(action.payload.params);
      if (data && data.status === 'success') {
        yield put(actions.fulfillDidacticTypes(data));
      }
    }
  });
}
