import { TYPES_CONTENTS } from 'app/services/contents.service';
import {
  PERSIST_AUTH,
  PERSIST_CUSTOM_TAGS,
  PERSIST_DIDACTIC_TYPES,
  PERSIST_EDUCATION_LEVELS,
  PERSIST_LANGS,
  PERSIST_LEARNING_OBJECTIVES,
  PERSIST_LOCATIONS,
  PERSIST_QUESTION_TYPES,
  PERSIST_SCOPES,
  PERSIST_SYSTEM_SETTINGS,
  PERSIST_TEMPLATES,
  PERSIST_TOPICS,
  PERSIST_TYPOGRAPHY,
  PERSIST_UI,
  PERSIST_USERS,
} from 'app/store/conf';

export function isModeProduction() {
  return process.env.NODE_ENV === 'production';
}

export function isInPublisher(defaultConfig) {
  return (defaultConfig.PUBLISHER_DOMAIN && window.location.hostname.indexOf(defaultConfig.PUBLISHER_DOMAIN) !== -1) || false;
}

export function copyClipboard(text) {
  navigator.clipboard.writeText(text);
}

export function getIdTypeByExtension(url, TYPES_FORMAT) {
  const types = getIdTypeByExtensionSanitize(TYPES_FORMAT);

  let extension = url.split('.').pop();
  extension = extension === 'jpg' ? 'jpeg' : extension;

  for (let type of types) {
    let find = type.formats.find((item) => item.format === extension);
    if (find) {
      return type.guid;
    }
  }

  return '';
}

function getIdTypeByExtensionSanitize(TYPES_FORMAT) {
  // Remove zip from guidsToModify
  const guidsToModify = [TYPES_CONTENTS.video];
  const modifiedArray = TYPES_FORMAT.map((item) => {
    if (guidsToModify.includes(item.guid)) {
      return {
        ...item,
        formats: item.formats.filter((format) => format.format !== 'zip'),
      };
    }
    return item;
  });

  const typesToRemove = [
    TYPES_CONTENTS.dependency,
    TYPES_CONTENTS.FIXED_LAYOUT,
    TYPES_CONTENTS.LDR_MINT,
    TYPES_CONTENTS.scorm,
    TYPES_CONTENTS.HTML_interactive,
    TYPES_CONTENTS.question,
  ];
  return modifiedArray?.filter((item) => !typesToRemove.includes(item.guid));
}

export function passArrayToString(array, attribute) {
  const object = {};

  array.forEach((element) => {
    object[element[attribute]] = element[attribute];
  });

  return object;
}

export const compareField = (a, b, field) => {
  if (a[field].toLowerCase() < b[field].toLowerCase()) {
    return -1;
  }
  if (a[field].toLowerCase() > b[field].toLowerCase()) {
    return 1;
  }
  return 0;
};

export function removeAllStorage() {
  removeStorage('persist:' + PERSIST_AUTH);
  removeStorage('persist:' + PERSIST_CUSTOM_TAGS);
  removeStorage('persist:' + PERSIST_EDUCATION_LEVELS);
  removeStorage('persist:' + PERSIST_LANGS);
  removeStorage('persist:' + PERSIST_LEARNING_OBJECTIVES);
  removeStorage('persist:' + PERSIST_LOCATIONS);
  removeStorage('persist:' + PERSIST_QUESTION_TYPES);
  removeStorage('persist:' + PERSIST_SCOPES);
  removeStorage('persist:' + PERSIST_SYSTEM_SETTINGS);
  removeStorage('persist:' + PERSIST_TEMPLATES);
  removeStorage('persist:' + PERSIST_TOPICS);
  removeStorage('persist:' + PERSIST_TYPOGRAPHY);
  removeStorage('persist:' + PERSIST_UI);
  removeStorage('persist:' + PERSIST_USERS);
  removeStorage('persist:' + PERSIST_DIDACTIC_TYPES);
}

export function setupAxios(axios, baseUrl, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();
      if (authToken) {
        config.headers.Authorization = `${authToken}`;
      }
      config.baseURL = baseUrl;
      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response && error.response.status == 403) {
        removeAllStorage();
        window.location.href = '/auth/login';
      }
      return {
        error: error.response,
      };
    }
  );
}

export function removeStorage(key) {
  try {
    localStorage.setItem(key, '');
    localStorage.setItem(key + '_expiresIn', '');
  } catch (e) {
    return false;
  }

  return true;
}

export function getStorage(key) {
  const now = Date.now();

  let expiresIn = localStorage.getItem(key + '_expiresIn');
  expiresIn = expiresIn === undefined || expiresIn === null ? 0 : expiresIn;
  expiresIn = Math.abs(parseInt(expiresIn));

  if (expiresIn < now) {
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log('getStorage: Error reading key [' + key + '] from localStorage: ' + JSON.stringify(e));
      return null;
    }
  }
}

export function getOptionByGuid(options, guid) {
  return options && guid ? options.find((option) => option.guid == guid) : null;
}

export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + '_expiresIn', schedule);
  } catch (e) {
    return false;
  }
  return true;
}
