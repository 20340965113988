import { systemSettings } from 'app/store/index';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useSelector } from 'react-redux';

function useFeatureFlag(flag) {
  const system = useSelector((state) => systemSettings.selectors.getSetting(state));
  const enable_advanced_programming = [
    'adminEscuelaCanConfigCourse',
    'adminEscuelaShowSettingEvaluation',
    'showEvaluationOptions',
    'showProgramArticles',
    'showProgramResources',
    'showProgramming',
    'showProgrammingTeachingLoad',
  ];

  const isAdvancedProgramming = enable_advanced_programming.includes(flag);

  const value = isAdvancedProgramming ? !!system.enable_advanced_programming : useFeatureFlagEnabled(flag);

  return { value };
}

export default useFeatureFlag;
