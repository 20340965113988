export const PERSIST_AUTH = 'i2c_auth';
export const PERSIST_CONTENTS = 'i2c_contents';
export const PERSIST_CUSTOM_TAGS = 'customTags';
export const PERSIST_DIDACTIC_TYPES = 'i2c_didacticTypes';
export const PERSIST_EDUCATION_LEVELS = 'i2c_educationLevels';
export const PERSIST_I18N = 'i18n';
export const PERSIST_LANGS = 'i2c_langs';
export const PERSIST_LEARNING_OBJECTIVES = 'i2c_learningObjectives';
export const PERSIST_LOCATIONS = 'locations';
export const PERSIST_QUESTION_TYPES = 'questions';
export const PERSIST_SCHOOLS = 'schools';
export const PERSIST_SCOPES = 'scopes';
export const PERSIST_SYSTEM_SETTINGS = 'system-setting';
export const PERSIST_TEMPLATES = 'templates';
export const PERSIST_TOPICS = 'i2c_topics';
export const PERSIST_TYPES = 'i2c_types';
export const PERSIST_TYPOGRAPHY = 'typographyProgram';
export const PERSIST_UI = 'ui';
export const PERSIST_USERS = 'users';

export const REDUCER_APP = 'app';
export const REDUCER_AUTH = 'auth';
export const REDUCER_CONTENTS = 'contents';
export const REDUCER_CUSTOM_TAGS = 'customTags';
export const REDUCER_DIDACTIC_TYPES = 'didacticTypes';
export const REDUCER_EDUCATION_LEVELS = 'educationLevels';
export const REDUCER_GENERAL_APP = 'entities';
export const REDUCER_I18N = PERSIST_I18N;
export const REDUCER_LANGS = 'langs';
export const REDUCER_LEARNING_OBJECTIVES = 'learningObjectives';
export const REDUCER_LOCATIONS = 'locations';
export const REDUCER_QUESTION_TYPES = 'questions';
export const REDUCER_SCHOOLS = 'schools';
export const REDUCER_SCOPES = 'scopes';
export const REDUCER_SYSTEM_SETTINGS = 'system-setting';
export const REDUCER_TEMPLATES = 'templates';
export const REDUCER_TOPICS = 'topics';
export const REDUCER_TYPES = 'types';
export const REDUCER_TYPOGRAPHY = 'typographyProgram';
export const REDUCER_UI = 'ui';
export const REDUCER_USERS = 'users';
